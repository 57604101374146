/* Base styles */
:root {
  --color-primary: #39585c;
  --color-primary-light: #f3fcfb;
  --color-gray: #6b7280;
  --color-gray-light: #f9fafb;
  --color-white: #ffffff;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --radius: 8px;
}

/* Layout */
.landing {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.features {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .features {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Feature cards */
.feature {
  padding: 2rem;
  border-radius: var(--radius);
  background-color: var(--color-white);
  border: 1px solid #e5e7eb;
  box-shadow: var(--shadow-sm);
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.feature--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.feature__coming-soon {
  position: absolute;
  top: 0.25rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.875rem;
  color: var(--color-gray);
  font-weight: 500;
}

.feature:hover {
  box-shadow: 0 2px 6px rgba(133, 201, 195, 0.4);
}

.feature--active {
  background-color: var(--color-primary-light);
  border-color: var(--color-primary);
}

.feature__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.feature__icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-gray);
}

.feature__icon--active {
  color: var(--color-primary);
}

.feature__title {
  font-size: 1rem;
  font-weight: 800;
  color: var(--color-gray);
  margin: 0;
}

.feature__title--active {
  color: var(--color-primary);
}

/* Address form */
.address-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.address-form__input {
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: var(--radius);
  outline: none;
  background-color: white;
}

.address-form__input:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.1);
}

.address-form__button {
  width: 100%;
  padding: 1rem;
  font-size: 1.125rem;
  color: var(--color-white);
  background-color: #00a99d;
  border: none;
  border-radius: var(--radius);
  cursor: pointer;
  transition: background-color 0.2s;
  text-transform: uppercase;
  font-weight: 500;
}

.address-form__button:hover {
  background-color: #009085;
}

/* Add map selection styles */
.map-selection {
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative;
}

.map-selection__label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-gray);
  margin-bottom: 0.5rem;
}

.map-selection__button {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: var(--radius);
  background-color: white;
  font-size: 1rem;
  color: var(--color-gray);
  cursor: pointer;
  text-align: left;
  position: relative;
  transition: all 0.2s ease;
}

.map-selection__button:hover {
  border-color: var(--color-primary);
}

.map-selection__button::after {
  content: '';
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.75rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.map-selection__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.25rem;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: var(--radius);
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 50;
  max-height: 400px;
  overflow-y: auto;
}

.map-selection__section {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.map-selection__section:last-child {
  border-bottom: none;
}

.map-selection__section-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-gray);
  margin: 0 0 0.75rem;
}

.map-selection__option {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  cursor: pointer;
}

.map-selection__checkbox {
  margin-right: 0.75rem;
  width: 1rem;
  height: 1rem;
  border: 2px solid #e5e7eb;
  border-radius: 0.25rem;
  appearance: none;
  cursor: pointer;
}

.map-selection__checkbox:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='white'%3E%3Cpath fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.map-selection__search {
  position: relative;
}

.map-selection__search::before {
  content: '';
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.map-selection__search-input {
  width: 100%;
  padding: 0.75rem;
  padding-left: 2.5rem;
  border: 1px solid #e5e7eb;
  border-radius: var(--radius);
  font-size: 0.875rem;
  color: var(--color-gray);
}

.map-selection__search-input::placeholder {
  color: #9ca3af;
}

.map-selection__search-input:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.1);
}

/* Chat interface */
.container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.chatContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chatHeader {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.closeButton {
  cursor: pointer;
}

.chatMessages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 80px; /* Add padding to prevent overlap with input */
}

.chatInputContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  z-index: 10;
}

.chatInputWrapper {
  display: flex;
  max-width: 48rem;
  margin: 0 auto;
}

.chatInput {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 9999px;
  font-size: 0.875rem;
  outline: none;
}

.chatInput:focus {
  border-color: #40c4aa;
}

.sendButton {
  position: absolute;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.5rem;
  background-color: #40c4aa;
  border: none;
  border-radius: 9999px;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendButton:hover {
  background-color: #35b399;
}

.sendIcon {
  width: 1.25rem;
  height: 1.25rem;
}

.modeToggle {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.chat-container {
  display: flex;
  /* height: 100vh; */
  max-width: 50rem;
  margin: 0 auto;
  /* height: calc(100vh - 100px); */
  display: flex;
  flex-direction: column;
}

.chat-sidebar {
  display: none;
  width: 16rem;
  background-color: var(--color-gray-light);
  border-right: 1px solid #e5e7eb;
}

@media (min-width: 768px) {
  .chat-sidebar {
    display: flex;
    flex-direction: column;
  }
}

.chat-sidebar__header {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.chat-sidebar__title {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}

.chat-sidebar__address {
  font-size: 0.875rem;
  color: var(--color-gray);
  margin: 0.25rem 0 0;
}

.chat-sidebar__content {
  padding: 1rem;
}

.chat-sidebar__button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background: none;
  border: 1px solid #e5e7eb;
  border-radius: var(--radius);
  cursor: pointer;
}

.chat-sidebar__button-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

/* Chat main area */
.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  scroll-behavior: smooth;
}

.message {
  display: flex;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.3s ease-out forwards;
}

.message--user {
  justify-content: flex-end;
}

.message__content {
  display: flex;
  /* align-items: flex-start; */

  gap: 0.5rem;
  max-width: 100%;
}

.message__bubble {
  max-width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 18px;
  line-height: 1.4;
}

.message--user .message__bubble {
  color: white;
  font-weight: 400;
}

.message--assistant .message__bubble {
  border-radius: 0;
  padding: 0;
  width: 95%;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10%;
  overflow: hidden;
  width: 36px;
  height: 36px;
  color: white;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #40c4aa;
}

.avatar__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: white;
  background-color: var(--color-primary);
}

/* Chat input */
.chat-input {
  border-top: 1px solid #e5e7eb;
  padding: 1rem;
  padding: 1rem;
  background-color: white;
  border-top: 1px solid #e0e0e0;
}

.chat-input__form {
  display: flex;
  gap: 0.5rem;
}

.chat-input__field {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: var(--radius);
  outline: none;
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.chat-input__field:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.1);
  border-color: #007aff;
  outline: none;
}

.chat-input__button {
  padding: 0.75rem;
  background-color: var(--color-primary);
  border: none;
  border-radius: var(--radius);
  color: var(--color-white);
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-input__button:hover {
  background-color: #059669;
  background-color: #0056b3;
}

.chat-input__button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.chat-input__button-icon {
  width: 1rem;
  height: 1rem;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.typing-indicator {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.typing-indicator__dot {
  width: 8px;
  height: 8px;
  background-color: #40c4aa;
  border-radius: 50%;
  margin-right: 4px;
  animation: typing 1s infinite;
}

.typing-indicator__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-6px);
  }
}
